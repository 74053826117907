import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../Context';
import { getMatrimonialProfileById, updateMatrimonialProfile } from '../../../actions/matrimonialAction';
import { getLoggedInUserId } from '../../../actions/authAction';
import Uploader from '../../photos/uploadPhotos';
import ViewPhotos from '../../photos/viewPhotos';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../loader';

function FourthStep() {
  const { matrimonialId, formValues } = useContext(AppContext);
  const loggedInUserId = getLoggedInUserId();
  const [photosUrls, setPhotosUrls] = useState([]);
  const [kundaliUrls, setKundaliUrls] = useState([]);
  const [originalProfileUrls, setOriginalProfileUrls] = useState([]);
  const [defaultPhotoIndex, setDefaultPhotoIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchExistingMatrimonyData = async () => {
    try {
      const existingMatrimonyData = await getMatrimonialProfileById(matrimonialId);
      setDefaultPhotoIndex(existingMatrimonyData?.profile?.defaultPhoto?.index);
      setPhotosUrls(existingMatrimonyData?.profile?.photos?.map((photo) => photo.url));
      setKundaliUrls(existingMatrimonyData?.profile?.kundali?.map((kundali) => kundali.url));
      setOriginalProfileUrls(
        existingMatrimonyData?.profile?.original_profile?.map((originalProfile) => originalProfile.url),
      );

      console.log('existing data in fourth step ', existingMatrimonyData);
      return existingMatrimonyData;
    } catch (error) {
      console.error('Error fetching existing matrimonial data:', error);
      return null;
    }
  };

  useEffect(() => {
    fetchExistingMatrimonyData();
  }, [matrimonialId]);

  const updateUrls = (type, result) => {
    if (type === 'photos') {
      setPhotosUrls(result?.profile?.photos?.map((photo) => photo.url) || []);
    } else if (type === 'kundali') {
      setKundaliUrls(result?.profile?.kundali?.map((kundali) => kundali.url) || []);
    } else if (type === 'original_profile') {
      setOriginalProfileUrls(result?.profile?.original_profile?.map((originalProfile) => originalProfile.url) || []);
    }
  };

  const createUpdatedData = (existingData, type, url, md5Code, mediaType) => {
    console.log('Existing ', existingData);
    return existingData?.[type]
      ? [
          ...existingData[type],
          {
            url,
            uploaded_by: loggedInUserId,
            uploaded_at: new Date().toISOString(),
            md5sum: md5Code,
            type: mediaType,
          },
        ]
      : [
          {
            url,
            uploaded_by: loggedInUserId,
            uploaded_at: new Date().toISOString(),
            md5sum: md5Code,
            type: mediaType,
          },
        ];
  };

  const updateData = async (type, url, md5Code, mediaType) => {
    try {
      let existingMatrimonyData = await fetchExistingMatrimonyData();
      const isFirstPhoto = existingMatrimonyData && !existingMatrimonyData?.profile?.defaultPhoto && type === 'photos';

      console.log('Existing Matrimony Data:', existingMatrimonyData);
      let updatedData;
      if (existingMatrimonyData?.profile) {
        console.log('Updated data before', updatedData);

        updatedData = createUpdatedData(existingMatrimonyData?.profile, type, url, md5Code, mediaType);
        console.log('Updated data after', updatedData);
      }

      const result = await updateMatrimonialProfile(matrimonialId, {
        ...existingMatrimonyData?.profile,
        [type]: updatedData,
      });
      updateUrls(type, result);

      // If it's the first photo uploaded, set it as default
      if (isFirstPhoto) {
        handleSetAsDefault(0);
      }
    } catch (error) {
      console.error(`Error updating matrimonial ${type}:`, error);
    }
  };
  const handleSetAsDefault = async (index) => {
    try {
      setLoading(true);
      // Fetch existing matrimonial data
      let existingMatrimonyData = await fetchExistingMatrimonyData();

      // Create updated data with default photo URL
      let updatedData;
      if (existingMatrimonyData?.profile) {
        updatedData = {
          ...existingMatrimonyData.profile,
          defaultPhoto: { url: existingMatrimonyData.profile.photos[index].url, index }, // Set default photo URL
        };

        // Update matrimonial profile
        const result = await updateMatrimonialProfile(matrimonialId, updatedData);
        setLoading(false);
        console.log('Matrimonial profile updated with default photo:', result);
        toast.success('Photo set as default');

        setDefaultPhotoIndex(index);
      }
    } catch (error) {
      toast.error(`Error setting default photo, ${error}`);
      setLoading(false);
    }
  };

  const handleDeleteButtonClick = async (index, type) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this item?');
      if (!confirmDelete) {
        return;
      }

      setLoading(true);
      let existingMatrimonyData = await fetchExistingMatrimonyData();

      if (type === 'photos' && index === defaultPhotoIndex) {
        toast.error('Default photo cannot be deleted');
        setLoading(false);
        return;
      }

      // Remove the item at the specified index based on the type
      let updatedData;
      if (existingMatrimonyData?.profile) {
        if (type === 'photos') {
          updatedData = {
            ...existingMatrimonyData.profile,
            photos: existingMatrimonyData.profile.photos.filter((photo, i) => i !== index),
          };
        } else if (type === 'kundali') {
          updatedData = {
            ...existingMatrimonyData.profile,
            kundali: existingMatrimonyData.profile.kundali.filter((kundali, i) => i !== index),
          };
        } else if (type === 'original_profile') {
          updatedData = {
            ...existingMatrimonyData.profile,
            original_profile: existingMatrimonyData.profile.original_profile.filter(
              (originalProfile, i) => i !== index,
            ),
          };
        }

        // Update matrimonial profile with the updated data
        const result = await updateMatrimonialProfile(matrimonialId, updatedData);

        // Update state with the new data URLs
        updateUrls(type, result);

        setLoading(false);
        console.log(`${type} deleted successfully:`, result);
        toast.success(`${type} deleted successfully`);
      }
    } catch (error) {
      console.error(`Error deleting ${type}:`, error);
      toast.error(`Error deleting ${type}:, ${error}`);
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      {loading && <LoadingSpinner />}
      <ViewPhotos
        photos={photosUrls}
        heading="Existing Photos"
        showCloseBtn={false}
        showSetDefault={true}
        showDeleteBtn={true}
        onDeleteButtonClick={(index) => handleDeleteButtonClick(index, 'photos')}
        defaultIndex={defaultPhotoIndex}
        handleSetAsDefault={handleSetAsDefault}
      />
      <div className="step-header">Upload Photo</div>
      <Uploader
        matrimonialId={matrimonialId}
        onUploadSuccess={(url, md5Code, mediaType) => updateData('photos', url, md5Code, mediaType)}
      />
      {/* <ViewPhotos
        photos={kundaliUrls}
        heading="Existing Kundali Photos"
        showCloseBtn={false}
        showDeleteBtn={true}
        onDeleteButtonClick={(index) => handleDeleteButtonClick(index, 'kundali')}
      />
      <div className="step-header">Upload Kundli</div>
      <Uploader matrimonialId={matrimonialId} onUploadSuccess={(url, md5Code) => updateData('kundali', url, md5Code)} /> */}

      <ViewPhotos
        photos={originalProfileUrls}
        heading="Existing Original Profile"
        showCloseBtn={false}
        showDeleteBtn={true}
        onDeleteButtonClick={(index) => handleDeleteButtonClick(index, 'original_profile')}
        showFullScreen={true}
      />
      {originalProfileUrls?.length > 0 ? (
        <p>You already have an original profile uploaded. Please delete the existing one to upload a new one.</p>
      ) : (
        <>
          <div className="step-header">Upload Original Profile</div>
          <Uploader
            matrimonialId={matrimonialId}
            onUploadSuccess={(url, md5Code, mediaType) => updateData('original_profile', url, md5Code, mediaType)}
            multipleUpload={false}
          />
        </>
      )}
    </div>
  );
}

export default FourthStep;
